.noborder {
  border: 0px none transparent;
}

.fullwidth {
  width: 100%;
}

.phoneInputContainer {

}

.phoneInputContainer input {
  background-color: #E9F6FE;
}
.phoneInputContainer label {
  position: absolute;
  //transform: translate(-25px, -20px) scale(0.75);
  white-space: nowrap;
  word-break: keep-all;
  color: rgba(0, 0, 0, 0.54)
}

.desktop-shortCard-container .MuiCard-root {
  overflow: visible;
}
.desktop-shortCard-container .MuiPaper-root {
  //overflow: visible;
}

.star-button {
  //background-image: url("../../assets/star.png");
  //background-repeat: no-repeat;
  //background-origin: content-box;
  //background-size: contain;
  //height: 68px;
  //width: 170px;
  background-color: transparent !important;
  position: relative;
  img {
    position: absolute;
    top: -20px;
    left: 14%;
    width: 80%;
    height: 90px;
    transform: scaleY(0.8);
    //margin: auto auto;
    //z-index: 10000;
    webkit-filter: drop-shadow(2px 2px 8px rgba(0,0,0,0.3)) drop-shadow(-2px -2px 8px rgba(0,0,0,0.3));
    filter: drop-shadow(2px 2px 8px rgba(0,0,0,0.3)) drop-shadow(-2px -2px 8px rgba(0,0,0,0.3));
    -ms-filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
    filter: "progid:DXImageTransform.Microsoft.Dropshadow(OffX=12, OffY=12, Color='#444')";
  }
  button {
    background-color: transparent;
    color: black;
    //z-index: 10001;
  }
}

.closeButton {
  position: absolute !important;
  right: 8px;
  top: 0px;
  color: gray;
}
