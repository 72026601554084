$main-background-color: #E9F6FE;
$color-transparent: transparent;
$color-gray: gray;
$color-gray2: #666666;
$color-lightgrey: lightgrey;
$color-ivory: #D3EEF4;
$color-white: white;
$color-ivory2: #F5F5F5;
$color-ivory: #E3E3E3;
$color-skyblue: #E9F6FE;
$color-lightorange: #EAB048;
$color-orange: #FFA80D;
$color-darkorange: #ffa40066;
$color-green: #92BB47;
$color-darkgreen: #95bb559e;
$color-black: black;
$color-red: red;
$color-white-transparent: rgba(0, 0, 0, 0.54) !important;

$color-new-items: #7A9F38;
$color-preorder: #0DA3C4;
$color-sale: #F20359;
$color-item-regular: gray;

$primary-color: gray;
$primary-button-color: white;

$error-color: red;
$error-border: 0px 0px 5px red;

%glow-green {
  box-shadow: 0px 0px 5px green;
}
%glow-red {
  box-shadow: 0px 0px 5px red;
}

%glow-blue {
  box-shadow: 0px 0px 5px blue;
}

%glow-skyblue {
  box-shadow: 0px 0px 5px skyblue;
}

