#liqpay_checkout {
  margin-top: 25px;
}

.errorAutocomplete {
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    transform: translate(14px, -12px) scale(0.75);
  }

  &.errorBox {
    @extend .errorBox;
    color: red;
    width: 100%;
    text-align: center;
  }
  //border: 2px solid rgba(255,0,0,.5);

  fieldset {
    //border: 2px solid rgba(255,0,0,.5);
  }
  .MuiInputBase-root {
    //border: 2px solid rgba(255,0,0,.5);
  }
  input {
    border: 2px solid rgba(255,0,0,.5);
  }
}
