/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

.auth-fullpage-content-autowidth {
  overflow: visible;
}

.auth-card {
  box-shadow: 0px 2px 4px rgba(0,0,0,.5);
  width: 250px;
  margin: 0 auto;
  padding-top: 20px;
  padding-left: 0px;
  padding-bottom: 20px;
  background-color: white;
  .buttonCoolContainer {
    display: flex;
    .actionButton {
      color: inherit;
      border: 0;
      cursor: pointer;
      margin: 0;
      display: inline-flex;
      outline: 0;
      padding: 0;
      position: relative;
      align-items: center;
      user-select: none;
      border-radius: 0;
      vertical-align: middle;
      -moz-appearance: none;
      justify-content: center;
      text-decoration: none;
      background-color: transparent;
      -webkit-appearance: none;
      -webkit-tap-highlight-color: transparent;
      flex: 1;
      color: rgba(0, 0, 0, 0.54);
      padding: 6px 12px 8px;
      max-width: 168px;
      min-width: 80px;
      transition: color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,padding-top 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
      //display: inline-flex;
      span {
        width: 100%;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        max-width: 168px;
        min-width: 80px;
      }
    }
    button {
      background-color: rgb(240,240,240) !important;
    }
    button:nth-child(1) {
      margin-right: 2px;
      margin-left: -10px;
      @extend %glow-green;
    }
    button:nth-last-child(1) {
      margin-left: 2px;
      margin-right: -10px;
      @extend %glow-red;
    }
  }
  button {
    background-color: rgb(240,240,240) !important;
  }
  button:nth-child(1) {
    margin-right: 2px;
    margin-left: -10px;
    @extend %glow-red;
  }
  button:nth-last-child(1) {
    margin-left: 2px;
    margin-right: -10px;
    @extend %glow-green;
  }

}