/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

.noWrap {
  flex-wrap: nowrap;
  text-wrap: none;
}

.groupSearchPage {
  margin-top: 120px;
  .name {
    font-weight: normal;
  }
  .bigImage {
    position: fixed;
    top: 90px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 400000;
    background-color: white;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-height: 100%;
      max-width: 100%;
    }
  }
  .header {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-bottom: 30px;
    max-width: 300px;
    margin: 20px auto;
    .artikulHeader {
      margin-left: 15px;
      font-weight: bold;
    }
  }
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .row {
    width: 100%;
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid lightgrey;
    padding-bottom: 5px;
    margin-bottom: 5px;
    &.rowStock {
      .main-content {
        width: 100%;
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        align-items: center;
        .action {
          flex-direction: row;
        }
      }
    }
    .refNum {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
    .size {
      font-weight: bold;
    }
    .action {
      justify-content: center;
      display: flex;
      flex-direction: column;
      padding-left: 5px;
      .shopping-plusButton.big {
        min-width: 36px !important;
        font-size: 1.2rem;
        font-weight: bold;
      }
      .inCart {
        font-size: 1.5rem;
        text-align: center;
        color: green;
        font-weight: bold;
      }
      button.inCart {
        background-color: transparent;
        border: none;
      }
    }
    .image {
      max-width: 120px;
    }
    .main-content {
      display: flex;
      flex-direction: column;
      font-size: .8rem;
      width: calc(100% - 100px);
      .descr {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      .chars {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .actionsPannel {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      .actionsPannel {
        display: none;
        padding-left: 5px;
      }
    }
  }
  .rowTwoItems {
    margin-bottom: .5rem;
    padding-bottom: .5rem;
    .image {
      img {
        width: 100%;
        height: auto;
      }
    }
    .main-content {
      //justify-content: center;
      align-items: center;
    }
    .refNum {
      display: flex;
      flex-direction: column;
      min-width: 4rem;
      text-align: center;
    }
    .name {
      height: 1.5rem;
      margin-bottom: .5rem;
    }
    .action {
      //margin-top: .8rem;
      .subaction {
        display: flex;
        .inCart {
          width: 3rem;
        }
      }
      .subaction-middle {
        height: 1rem;
      }
    }
    .size {
      font-size: 1.5rem;
      //margin-bottom: .2rem;
      //margin-top: .2rem;
    }
    .image {
      margin-right: 5px;
    }
    .chars {
      justify-content: flex-start;
    }

    .shopping-plusButton.big {
      min-height: 24px !important;
    }
    .color-price {
      flex-direction: column;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }
    .action {
      .inCart {
        font-size: 1.2rem;
        text-align: center;
        color: green;
        font-weight: bold;
      }
    }
  }
}

//.groupSearchPage {
//  margin-top: 120px;
//  .container {
//    width: 100%;
//    display: flex;
//    flex-direction: column;
//    padding-right: 10px;
//    padding-left: 10px;
//    max-width: 95%;
//    border-top: 1px solid lightgrey;
//    .row {
//      width: 100%;
//      display: flex;
//      justify-content: space-between;
//      align-items: center;
//      align-content: center;
//      border-bottom: 1px solid lightgrey;
//      padding-bottom: 5px;
//      margin-bottom: 5px;
//      .image {
//        max-width: 100px;
//      }
//      .content {
//        .inCart {
//          margin-right: 5px;
//        }
//
//        .price {
//          display: flex;
//          flex-direction: column;
//          justify-content: center;
//
//          span {
//            width: 100%;
//            text-align: center;
//          }
//        }
//
//        .descr {
//          .div {
//            display: inline-flex;
//            flex-wrap: nowrap;
//            width: 100%;
//          }
//        }
//
//        div {
//          align-items: center;
//        }
//
//        .price {
//
//        }
//
//        .actionsPannel {
//          display: inline-flex;
//          flex-wrap: nowrap;
//          justify-content: space-between;
//
//          button {
//            min-width: 2rem !important;
//            min-height: 2rem !important;
//            max-height: 2rem !important;
//            line-height: 2rem;
//            margin-right: 5px;
//          }
//        }
//
//        .MuiSvgIcon-root {
//          font-size: 1.5rem;
//        }
//      }
//    }
//  }
//}
