.views-content-page {
  h1 {
    font-family: Roboto,sans-serif;
    color: #212529;
    line-height: 1.2;
    margin-bottom: .5rem;
    font-weight: 500;
  }
  h2 {
    font-family: Roboto,sans-serif;
    color: #212529;
    line-height: 1.2;
    margin-bottom: .5rem;
    font-weight: 400;
  }

  p {
    font-weight: 300;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529;
  }

  b, strong {
    font-weight: 500;
  }

  table {
    border-spacing: 0px 0px;
    width: 100%;
    margin: 0 0;
  }
  td {
    border-top: 1px solid gray;
    border-bottom: 1px solid gray;
  }

  video {
    width: 100%;
  }

  img {
    width: 100%;
  }
}