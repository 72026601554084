%display-block {
  display: block;
}
%display-flex {
  display: flex;
}

%standard-button {
  border-radius: 0px;
  color: white;
}

%width-two-three {
  min-width: 66%;
  width: 66%;
}

.vertical-button-group {
  display: block;
  button {
    width: 100%;
  }
}

.horizontal-button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  button {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 1.2rem;
  }
}