/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

.needRegisterButton {
  padding: 10px 10px;
  margin-top: 15px;
  text-decoration: none;
}

.auth-form {
  .errorBox {
    color: red;
  }
  .phoneInputContainer {
    margin-top: 25px;
    input {
      margin-top: 0px;
    }
  }
  label {
    transform-origin: top left;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    //display: block;
    color: rgba(0, 0, 0, 0.54);
    position: absolute;
    //top: 0;
    left: 0;
    //transform: translate(0,1.5px) scale(0.75);
    transform: none;
    transform: translate(45px, -16px) scale(0.75);
    white-space: nowrap;
    word-break: keep-all;
    text-align: left;
  }
  .standardInput {
    margin-top: 16px;
  }

  .auth-field-error-box {
    color: red;
  }
}
