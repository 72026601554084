/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

.cart-no-items-block {
  text-align: center;
}

.shoppingCartImg {
  width: 50px;
  height: 50px;
  position: relative;
  float: left;
  margin-right: 10px;
}

.shoppingCartImgInner {
  display:flex;

  justify-content: center; /*центрируем элемент по горизонтали */
  align-items: center; /* и вертикали */
  width: 50px; /* задали размеры блоку-родителю */
  height: 50px;
  overflow: hidden; /* если элемент больше блока-родителя – обрезаем лишнее */
}

.shoppingCartImgInner img {
  width: 50px;
  height: auto;
}

.shoppingCartInput {
  width: 40px;
  text-align: center;
}

.shoppingCartInput input {
  width: 40px;
  text-align: center;
  color: black;
  font-size: 1.5em;

}

.shoppingCartInput::before {
  border-bottom: none !important;
}

.shoppingCartRightToolbar {
  width: 100%;
}

.shoppingCartRightToolbarContent {
  float: right;
}

.shoppingCartRightToolbarContent span {
  padding-left: 2em;
}

#shoppingCartAccName {
  text-align: right;
}

#shoppingCartSummary {
  text-align: right;
}

#shoppingCartOrderID {
  text-align: right;
}
#shoppingCartOrderBtn {
  text-align: right;
}

.cart-top-button-conainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 1rem;
  .backButton {
    margin-left: 1rem;
  }
  .cart-buttons-container-right-button {
    margin-right: 1rem;
    padding-right: 0;
  }
}

//.containers-shoppingTable-parts-row {
//  display: flex;
//  align-items: center;
//  justify-content: space-around;
//  width: 100%;
//  flex-direction: row;
//}

.additionalAdminPanel {
  border-top: 1px dotted gray;
  border-bottom: 1px dotted gray;
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 5px;
}
