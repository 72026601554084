.cart-buttons-container-right-button {
  padding-right: 10px;
}
.shoppingCart-header-button-additional.hide-opacity {
  opacity: 0;
}
.shoppingCart-header-button-additional {
  position: absolute;
  top: 100px;
  right: 0px;
  font-size: 4rem;
  font-weight: bold;
  color: red;
}

.cart-additional-animate {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s 2s, opacity 2s linear;
}

.cart-top-container {
  max-width: 1440px;
  margin: 0 auto;
  padding-right: 0px;
  padding-left: 0px;
  .cart-button-conainer {
    margin-left: 0px;
    .cart-buttons-container-right-button {
      padding-right: 0px;
    }
  }
}

.views-cartPage-body {
  fieldset {
    padding-right: 0px;
    padding-left: 0px;
    .fullwidth {
      padding-right: 0px;
      padding-left: 0px;
    }
    ::placeholder {
      font-size: 1.4rem;
      padding-left: 10px;
      color: lightgray;
    }
    //::-webkit-input-placeholder {
    //  font-size: 2rem;
    //}
    //:-moz-placeholder {
    //  font-size: 2rem;
    //}
    //::-moz-placeholder {
    //  font-size: 2rem;
    //}
    //:-ms-input-placeholder {
    //  font-size: 2rem;
    //}
  }
}