/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

.advertiseCounter {
  position: absolute;
  bottom: 0;
  padding-bottom: 1rem;
  padding-top: 1rem;
  padding-left: .3rem;
  padding-right: .3rem;
  background-color: rgba(255,255,255,.5);
  left: 0;
  right: 0;
  font-size: 2rem;
  color: red;

  .advertiseCounter-timer {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    .small {
      font-size: .8rem;
    }
  }

  .advertise-timesLabels {
    font-size: .8rem;
  }

  @media all and (max-width: 1600px) {
    font-size: 1.6rem;
  }

  @media all and (max-width: 1200px) {
    font-size: 1.8rem;
  }

  @media all and (max-width: 1024px) {
    font-size: 1.8rem;
  }

  @media all and (max-width: 800px) {
    font-size: 2.5rem;
  }

  .advertisementButton {
    color: #005cbf;
    font-size: 1rem;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-weight: bolder;
    background-color: #FFA80D;
    height: auto;
    border: none;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    box-shadow: 2px 4px 6px black;
    //margin-left: -10px;
    margin-bottom: 1rem;
    width: calc(100% - 80px);

    @media all and (max-width: 800px) {
      font-size: 1.2rem;
    }
  }
  .advertise-subtitle {
    color: rgb(50,50,50);
    font-size: 1rem;
  }
}
