/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */


.detailImageArea {
  height: 100%;
  text-align: center;
}

.detailImageArea img {
  width: auto;
  height: 100%;
  margin: auto;
}
