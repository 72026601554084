/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2019 Serhii Kondrashov
 */

.views-orderPage-dataArea {
  table {

    .standardButton {
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
      box-shadow: 1px 1px 2px gray;
      text-decoration: none;
    }

    .standardButton:hover {
      background-color: lightgreen !important;
      color: black;
    }

    td {
      line-height: 1.5rem;
      padding-bottom: 5px;
      span {
        br {
          height: 1px;
        }
      }
    }
  }
}