.mobileLayout {
  .filter-drawer-root {

  }
  .filter-drawer-paper{
    width: 66%;
  }
}

.filters-sections-partialSelected {
  min-width: 24px !important;
  padding-left: 0px;
  padding-right: 0px;
  .MuiButton-label {

  }
}

.sizes-filter-subblock-header {
  background-color: skyblue;
  font-size: 1rem;
  padding-left: 1rem;
  padding-top: 5px;
  padding-bottom: 5px;
}

.filter-row-name.disabled {
  color: lightgray;
}

.disabledSearchModeOverlay {
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,.5);
  z-index: 10000;
  //display: inline-flex;
  //align-content: baseline;
}

.disabledSearchModeOverlay div {
  position: relative;
  margin: auto 0;
  top: 30%;
  color: white;
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  font-weight: bold;
}

.views-filterPage-filterArea-root:nth-last-child(1){
  //margin-bottom: 60px;
}
