@import "colors";
@import "blocks";
@import "components/overlay-containers";
@import "components/advertisement";
@import "pages/firstPage";
@import "pages/filters";
@import "pages/checkout";
@import "pages/barscan";
@import "pages/shoppingCart";
@import "pages/products";
@import "pages/cart";
@import "pages/detail";
@import "pages/auth";
@import "pages/orders";
@import "pages/dropshipping";
@import "modules/auth";
@import "searchgroup";
@import "containers";


@import "common";

@-ms-viewport {
  width: device-width;
  zoom: 1.0;
  initial-scale: 1;
  user-scalable: no;
  shrink-to-fit: no;
  maximum-scale: 1
}

html,body {
  margin: 0;
  padding: 0;

  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  /*font-family: Roboto, sans-serif;*/
  font-style: normal;
  font-weight: normal;
  height:100%;
  margin:0;
  -moz-user-select: none;
  -khtml-user-select: none;
  user-select: none;

  overscroll-behavior: contain;
  touch-action: swipe;
  /* transition: all 2s; */
  /* background-color: #E9F6FE;*/
}

#root {
  height: 100%;
  position: absolute;
  width: 100%;
  bottom: 0px;
}

.containers-header-mainMenuDrawer {
  .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
    img {
      max-width: 100px;
    }
  }
  .MuiToolbar-gutters {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.category-has-subitems {
  justify-content: space-between !important;
  padding-right: 4px !important ;
  align-items: center;
  background-color: #F7F7F7 !important;
  .pull-right {
    // float: right;
    padding-top: 8px;
  }
}

.desktopLayout {
  padding-right: 0px !important;
  .views-ordersPage-container {
    margin-top: 90px;
  }
  .views-content-page {
    margin-top: 90px;
  }
  .views-cartPage-body {
    margin-top: 90px;
  }
  .views-content-page {
    margin-top: 90px;
  }
  .profilePage-container {
    margin-top: 90px;
  }
}

div[role=presentation] {
  // overflow-y: scroll !important;
  // overflow-x: hidden !important;
  // margin-right: 5px;
}

div[role=presentation]::-webkit-scrollbar:hover {
  width: 18px;
}
div[role=presentation]::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
  padding-right: 0px;
}

div[role=presentation]::-webkit-scrollbar-thumb {
    background-color: gray;
}

div[role=presentation]::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
    background-color: #F5F5F5;
}

.menu-list-grow.MuiPaper-rounded{
  border-radius: 0px;
}
.menu-list-grow {

  ul {
    padding-top:0px;
    li:nth-child(1) {
      border: 1px solid $color-lightorange;
      margin-right: -1px;
      margin-left: -1px;
    }
  }
}

%white-color-button {
  color: white;
}

html, body {
  background-color: $main-background-color;
  overflow: inherit !important;
}

.desktopLayout {
  button {

    background-color: $color-transparent;
  }

  .plusButton {
    @extend %white-color-button;
  }

  .nenaborButton {
    @extend %white-color-button;
  }

  .desktop-shortCard-container-bigCard {

  }

}

/***** Flexbox components *****/
body {
  .views-filterPage-filterArea-singles-container {
    @extend %display-flex;
  }

  .views-checkoutPage-container-toolbar {
    @extend %display-flex;
  }
  .actionButtonContainer {
    @extend %display-flex;
  }
  button {
    @extend %standard-button;
  }
  .standardButton {
    @extend %standard-button;
  }
  .backButton {
    @extend %standard-button;
  }

  @import "pages/content";
}

.desktopLayout {
  .products-catalog-root {
    .MuiDrawer-paper {
      max-width: 250px;
      width: 250px;
    }
  }
  .header-appbar-menu {
    @media (max-width: 1279px) {
      margin-left: 0px;
      .header-textMenu-item {
        padding-left: 20px;
      }
      button {
        padding: 8px 4px;
      }
    }
    @media (min-width: 1280px) {
      margin-left: 120px;
    }
    button {
      color: #383201;
      padding: 0 0;
      text-transform: none;
      font-size: 1rem;
      font-weight: normal;
    }
  }
  .header-appBar-logo {
    margin-left: 90px;
    height: 46px !important;
  }
}

.mobileLayout {
  .products-catalog-root {
    .MuiDrawer-paper {
      @extend %width-two-three;
    }

    li {
      border-bottom: 1px solid lightgrey;
      padding-top: 4px;
      padding-bottom: 4px;
      padding-right: 4px;
    }

    li.no-bottom-line {
      border-bottom: none !important;
      border-top: none !important;
    }
  }
}

.catalog-toggle-button {
  font-size: 1rem;
}

.containers-header-secondToolbar {
  display: flex;
  justify-content: space-between;
}

.containers-header-appBar  {
  button {
    @media (max-width: 1279px) {
      margin-left: 5px !important;
    }
    @media (min-width: 1280px) {
      margin-left: 15px !important;
    }
  }
}

.containers-header-appBar-toolbarBrandTitle {
  img {
    height: 48px;
    padding-top: 6px;
  }
}

.containers-shoppingTable-parts-secondCell {
  text-align: center;
}

.nenabor-drawer-root {
  .nenabor-table-container {
    overflow-x: hidden;
    .nenabor-table td:nth-of-type(2) {
      font-weight: bold;
    }

    .nenabor-table td:nth-of-type(1) {
      padding-left: 28px;
      font-size: 1.1rem;
    }
  }
  .backButton {
    margin-top:15px;
  }
}

.layout-center {
  min-height: 50%;
  position: absolute;
  width: 100%;
  text-align: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

.layout-center-inner {
  width: 100%;
  margin: auto;
}

.catalog-menu-root {
  z-index: 10000;
  min-width: 230px;
  //left: -300px;
}

.catalog-toggle-button {

}

.shoppingCart-error h3 {
  color: black;
}

.timer {
  color: red;
  margin-top: 20px;
  font-size: 3rem;
  font-weight: bold;
}


.shortCard-mobile-container {
  height: 100%;
  width: 100%;
}



.fullscreen {
  height:100%;
  min-height:100%;/*for mozilla*/
}

.cartBadge {
  text-shadow: -1px 0px 1px #666666;
  font-weight: bold;
}

.shoppingCartImgBasket {
  float: left;

}

.nameCell {
  width: '100%'
}



.clearFilterButton {
  min-height: 26px !important;
  padding-top: 1px !important;
  padding-bottom: 1px !important;
  font-size: .7em !important;
}

.clearFilterButtonContainer:parent {
  display: block !important;
}


.yellow-text {
  color: yellow;
}

.noBottomRow td {
  border-bottom: none;
  border-bottom-width: 0px;
  border-bottom-style: none;
}

.pinchView div div div div div {
  height: auto !important;
}

.pinchView div div div div {
  bottom:auto !important;
}

.nenabor {
  background-color: beige;
}

.shoppingCartRow td {
  font-size: 0.8125rem;
}

.expansionPanelSummary {
  background-color: lightgray !important;
}

.sticky {
  position: sticky !important;
  top: 0px;
  z-index: 1000;
}

.sticky-50 {
  position: sticky !important;
  top: 50px;
  z-index: 1000;
}
.sticky-100 {
  position: sticky !important;
  top: 100px;
  z-index: 1000;
}
.sticky-150 {
  position: sticky !important;
  top: 100px;
  z-index: 1000;
}

.alignRight {
  text-align: right;
  padding-right: 1em;
}

.checkBoxRight {
  float: right;
  height: 30px;

}

.checkBoxRight span {
  height: 30px;
}

.selectedRow {
  background-color: beige;
}

.cartTableHead {
  /*    position: sticky;
      background: white;
      top: 0px;
      z-index: 1000;
      */
}

.wideCartBage span {
  width: 140%;
  left: 15px;
  text-shadow: 2px 2px 2px black;
}

.bigIcon span svg {
  font-size: 32px !important;
}


.actionButtonContainer {
  color: white;
}

.orangeText {
  color: $color-orange !important;
}

.whiteText {
  color: $color-white !important;
}

.logger-box {
  position: fixed;
  bottom: 0px;
  height: 120px;
  background-color: rgba(0,0,0,.3);
  width: 100%;
  left: 0px;
  overflow-y: scroll;
}


.conditionalText {
  width: 100%;
  margin: 1rem 1rem 1.5rem 1rem;
}

.startReserveButton {
  min-width: 150px;
  border: 1px solid black;
  color: black;
  //box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  padding: 5px 5px;
  margin-left: 10px;
  &:disabled {
    color: grey;
    border: 1px solid grey;
  }
  &.startReserveButton-active {
    border: 1px solid green;
    color: white;
    background-color: green;
    //box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
}

.managerAutocompleteSearchButton {
  font-size: 1.3rem;
  margin-top: 5px;
  margin-bottom: 5px;
}

.standardButton {
  cursor: pointer;
  &[disabled]{
    background-color:lightgray !important;
  }
  &:not([disabled]) {
    &.animated {
      &:hover {
        box-shadow: 0px 15px 25px -5px rgba(0, 0, 0, .5);
        transform: scale(1.03);
      }

      &:active {
        box-shadow: 0px 4px 8px rgba(0, 0, 0, .5);
        transform: scale(.98);
      }
    }
  }
}
