/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

.dropshippingPage {
  padding-bottom: 10rem;
  .backButton {
    z-index: 10000;
  }
  .dropshippingPage-content {
    max-width: 400px;
    margin: 0 auto;
    @media all and (max-width: 600px ){
      margin-top: 50px;
    }
    a.standardButton {
      padding: 5px 5px 5px 5px;
      justify-content: center;
      align-items: center;
      display: inline-flex;
      text-decoration: none;
    }
  }
  .regform {
    label {
      text-align: left;
      justify-content: flex-start;
      color: rgba(0,0,0,.54);
      font-family: Roboto,sans-serif;
    }
    input {
      padding: 15px;
      background-color: #e9f6fe;
      box-sizing: border-box;
      box-shadow: 0 2px 4px rgba(0,0,0,.5);
      border: none;
    }
    .message {
      &.errorBox {
        margin-top: 0px;
        transform: translateY(-10px);
        text-align: left;
        font-size: 1rem;
        color: orangered;
      }
    }
  }
  .container-flex-column {
    margin-right: 0px;
    padding-right: 10px;
  }
  .toolbar {
    .title {
      font-weight: bold;
      font-size: 1.3rem;
      position: absolute;
      width: 100%;
      text-align: center;
      left: 0;
      @media all and (max-width: 600px ){
        padding-top: 75px;
      }
    }
  }

  .infoblock {
    text-align: center;
  }

  .linearForm {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    .add {
      width: 2.5rem;
      height: 2.5rem;
      font-size: 2rem;
      color: $color-orange;
    }
    button {
      margin-left: 1rem;
      border: 1px solid lightgrey;
    }
    textarea {
      min-height: 5rem;
    }
    .form-icon {
      margin-left: 1rem;
    }
  }

  .paper {
    //margin: 0 0 0 0;
    padding: 2rem 2rem 2rem 2rem;
    margin-bottom: 1rem;
    box-shadow: 0 0 20px 0 rgba(0,0,0,.15), 0 5px 5px 0 rgba(0,0,0,.15);
  }

  .block-1-3 {
    width: auto;
  }

  .text {
    text-align: center;
    margin-bottom: 1rem;
  }

  .about {
    text-align: center;
    margin: 25px 5px 25px 5px;
    font-width: 1.2rem;
    font-style: italic;
  }
  .request-under-review {
    margin-bottom: 2rem;
    color: darkgreen;
  }

  footer {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    text-align: center;
    color: blueviolet;
    background-color: white;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .standardInput {
    width: 95%;
  }

  .standardButton {
    border: none;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 10px;
  }

  .bottom-flex-container {
    text-align: center;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .red {
    color: $color-red;
  }

  .green {
    color: green;
  }

  .text-span {
    width: 24px;
    font-weight: bold;
    font-size: 2rem;
  }
  .errorBox {
    width: 100%;
    text-align: center;
    color: $color-red
  }
  .errorInput {
    border: 2px solid rgba(255,0,0,.5) !important;
  }
}
