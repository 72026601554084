html, body {
  font-size: 16px;
}

body {
  overflow-y: scroll;
  scrollbar-width: thin;
}
body::-webkit-scrollbar-thumb {
  background-color: gray;
}
body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.15);
  background-color: #F5F5F5;
}
body::-webkit-scrollbar:hover {
  width: 14px;
}
body::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
  padding-right: 0px;
}

.desktopDrawer .inner {
  /* width: auto; */
}
.desktopDrawer .nenabor-table-container {
  background-color: white;
  padding: 0px 20px;
  padding-bottom: 10px;
}
.desktopDrawer {
  height: auto !important;
  position: fixed;
  top: 50% !important;
  left: 50% !important;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /*



   */
}

.desktopLayout .appBar-search-container {
  margin-right: 15px;
}

.AdvertiseCard {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
}
.AdvertiseCard img {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
}


.desktopLayout .views-filterPage-filterArea-section.views-filterPage-filterArea-singles {
  background-color: white;
}
.desktopLayout .views-filterPage-filterArea-section {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #E9F6FE;
}
.desktopLayout .views-filterPage-filterArea-section.bordered {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
}
.desktopLayout .cart-top-container ~ .views-cartPage-body {
  padding-top: 80px;
}
.desktopLayout .cart-button-conainer {
  margin-top: 30px;
  width: 100%;

}

.desktopLayout .productList-products-container-bottom {
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.desktopLayout {
}

.shortCardContainer .shortCard-priceBlock .shortCard-currentPrice {
  display: block;
  box-shadow:
}

.desktopLayout .containers-shoppingTable-overlay-top {
  top: 0px;
  left: 180px;
}
.desktopLayout .layouts-fullscreen-root,
.desktopLayout .auth-fullpage {
      position: initial;
}

.desktopLayout .plusButton {
  font-size: 1.2em;
}
.desktopLayout .nenaborButton,
.desktopLayout .plusButton {
  font-size: 1.2em;
}
.desktopLayout .firstPage-inner-auto div {
  /* background-color: transparent; */
}

.desktopLayout .filter-row-name {
  width: 100%;
  font-size: 12px !important;
}

.desktopLayout .appBar-searchField::before{
  border-bottom: none !important;
}
.desktopLayout .appBar-searchField span svg {
  color: gray;
  cursor: pointer;
}
.desktopLayout .appBar-searchField span:hover svg {
  color: black;
}
.desktopLayout .appBar-searchField {
  border: 1px solid rgba(0, 0, 0, 0.15);
  padding-left: 5px;
  margin-right: 10px;
  display: flex;
  align-items: center;
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
}
.appBar-searchField label {
}
.appBar-searchField input {
}
.appBar-searchField div::after,
.appBar-searchField div::before {
}

.appBar-search-container {
  white-space: nowrap;
}
