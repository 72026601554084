html, body {
  font-size: 16px;
}

body .mobileLayout {
  height: 100%;
}

input {
  font-size: 1.4rem !important;
}

.desktop-shortCard-container-bigCard-blueLine span span {
  font-size: 1.1rem !important;
  font-weight: bold;
}
.mobileLayout .desktop-shortCard-container-bigCard-blueLine span {
  font-size: 0.9rem;
}
.mobileLayout .desktop-shortCard-container-bigCard-blueLine {
  padding-left: 5px;
  padding-right: 5px;
  margin-top: 1rem;
}

.mobileLayout .firstPage-bottomBlock {
  overflow-x: hidden;
  width: 95%;

}
.mobileLayout .profilePage-container .profilePage-container-toolbar {
  margin-top: 10px;
  margin-bottom: 20px;
}
.mobileLayout .profilePage-container .standardButton {
  color: white !important;
}
.mobileLayout .profilePage-container .textInput {
  margin-right: 10px;
}
.mobileLayout .profilePage-container input {
  width: 100%;
  margin-right: 10px;
}
.mobileLayout .clearFilterButtonContainer {
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: white;
}

.mobileLayout .containers-MainMenu-currencyChanger-container {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 2rem;
}

.mobileLayout .detailPage-buyBox-noContent {
  /*width: 30%;*/
  min-width: 75px;
  /*margin-left: 10px;*/
}
.mobileLayout .detailPage-infoBlock-buyZone {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.detailPage-infoBlock-buyZone .actionButtonContainer {
  width: 100%;
}
.detailPage-infoBlock-buyZone .cart-actions-pannel-select-variant-button {
  width: 170px;
  margin-right: 5px;
}

.detailPage-infoBlock-ones div {

}

.mobileLayout .detailPage-infoBlock-ones .plusButton,
.mobileLayout .detailPage-infoBlock-ones .plusButton:hover {
  background-color:#EAB048;
}

.mobileLayout .containers-shoppingTable-overlay-top {
  top: 30px;
}
.mobileLayout .cart-top-container {
  padding-bottom: 5px;
}

.mobileLayout .views-cartPage-body {
  padding-top: 100px;
}

.mobileLayout p {
  line-height: 1.2rem;
  font-size: 1rem;
  margin-top: 2px;
  margin-bottom: 2px;
  /* margin-left: 3px; */
  padding-right: 10px;
}
.mobileLayout h2 {
  font-size: 1.3rem;
}

.shortCard-BottomNavigation-container .buttonPlaceholder {
  margin: 0 auto;
}
.shortCard-BottomNavigation-container .minusButton {
  height: 2rem;
  min-height: 2rem;
  width: 2rem;
  min-width: 2rem;
}
.shortCard-BottomNavigation-container .minusButton svg {
  font-size: 1.8rem !important;
}
.shortCard-BottomNavigation-container input {
  font-size: 1.8rem;
}


.shortCard-BottomNavigation-container > div,
.shortCard-BottomNavigation-container > button {
  margin: 0 auto;
}
.shortCard-BottomNavigation-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  /*max-width: 270px;*/
  white-space: nowrap;

}

.mobileLayout .views-filterPage-filterArea-block-filterNameDiv {
  font-size: 1.2rem;
}
.mobileLayout .filter-row-name {
  width: 100%;
  font-size: 16px !important;
}

.mobileLayout header svg {
  font-size: 32px;
}
.mobileLayout .containers-header-filterButton {
  font-size: 14px;
  font-weight: normal;
}
.mobileLayout .containers-header-appBar-toolbarBrandTitle {
  width: 10px;
  margin: 0px;
}

.mobileLayout .containers-shoppingTable-parts-row-subrow td {
  padding-right: 1px !important;
  padding-left: 1px !important;
}
.mobileLayout .cart-orderButton {
  height: 46px;
  font-size: 14px;
}
.mobileLayout .login-form label {
  font-size: 12px;
}
.mobileLayout .login-form input,
.mobileLayout #login_password {
  font-size: 1.5em;
  padding-left: 5px;
  padding-right: 5px;
}

.mobileLayout .cardInner {
  height: calc(100% - 3.5rem);
}
.mobileLayout .shortCard-shortCardContent {
  bottom: 3.5rem;
}
.mobileLayout .shortCard-sizes {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.15);
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.mobileLayout .nenaborButton > span,
.mobileLayout .plusButton > span {
  padding-top: .3rem;
}
.mobileLayout .plusButton {
  font-size: 1.8rem;
  height: 3.125rem;
  width: 6rem;
  max-width: 6rem;
  min-width: 6rem;
  margin-top: .1rem;
  border-radius: 0px;
}


.mobileLayout
{
  background-color: #E9F6FE;
}

.mobileLayout .containers-header-appBar,
.mobileLayout .containers-header-appBar div
{
  background-color: white;
}


.mobileLayout .detailTable {
  font-size: .8rem;
}

.mobileLayout .detailTable tr:nth-of-type(even) td {
  background-color: #E9F6FE;
}

/******************** Main menu ******************/
.containers-header-mainMenuDrawer-paper {
  width: 100%;
  background-color: #E9F6FE !important;
}
.containers-MainMenu-currencyChanger-container
.containers-MainMenu-currencyChanger-switch-bar {
  background-color: #EAB048 !important;
  opacity: 1 !important;
  height: 2rem !important;
  margin-top: -0.975rem;
  margin-left: -1.5rem;
  border-radius: 1.125rem;
  width: 3rem;
}
.containers-MainMenu-currencyChanger-container
.containers-MainMenu-currencyChanger-switch
.MuiSwitch-thumb {
  width: 1.7rem;
  height: 1.7rem;
}

.containers-MainMenu-currencyChanger-switch .MuiIconButton-root  {
  padding-top: 5px;
}
.containers-MainMenu-currencyChanger-switch .MuiSwitch-track {
      width: 4.6rem;
  height: 1.45rem;
    }
.containers-MainMenu-currencyChanger-switch .MuiSwitch-root {
  width: 4rem;
  padding-top: 8px;
}
.containers-MainMenu-currencyChanger-container
.containers-MainMenu-currencyChanger-switch
span
span
{
  color: white;


  /* width: 1.75rem; */
}
.containers-MainMenu-currencyChanger-container .containers-MainMenu-currencyChanger-switch .UAH {
  display: flex;
  align-content: center;
  align-items: center;

}
.containers-MainMenu-currencyChanger-container .containers-MainMenu-currencyChanger-switch {
  width: 49%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.containers-MainMenu-currencyChanger-container .exchangeRate {
  margin-right: 1rem;
  font-size: 1.625rem;
  width: 49%;
}
.containers-MainMenu-currencyChanger-container {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}
.containers-MainMenu-items-container > li {
  margin-top: 1.5rem;
  margin-bottom: 1.8rem;
  text-align: center;
}

.containers-MainMenu-items-text {
  padding: 0px;
  text-align: center;
  font-size: 1.25rem;
}
/******************** Main menu ******************/


/******************* Detail Page *****************/
.mobileLayout .detailPage-drawer-container {
}
.mobileLayout .detailPage-drawer-root {
  height: 100%;
  overflow-y: scroll;
  position: relative;
}
.mobileLayout .detailPage-container {
}
.mobileLayout .imageBlock-container {
  width: 100%;
  position: relative;
  clear: both;
  float: none;
}
.mobileLayout .detailPage-imageBlock {
  width: 100%;
  float: none;
  clear: both;
  height: 100%;

}
.mobileLayout .detailPage-imageBlock-thumbs-container {
  width: 20%;
  max-height: 50%;
  height: 50%;
  overflow-y: scroll;
  left: 0px;
  top: 55px;

}

.mobileLayout .detailPage-imageBlock-thumbs-container img {
  width: 100%;
}

.mobileLayout .detailPage-imageBlock-mainImage {
  width: 79%;
  max-height: 100%;
  height: 100%;
  max-width: 79%;
  left: 21%;
  position: absolute;
  top: 0px;
}

.mobileLayout .detailPage-imageBlock-mainImage button {
  height: 50%;
  top: 56px;
}

.mobileLayout .plusButton {
  background-color: #92BB47;
}
.mobileLayout .nenaborButton,
.mobileLayout .plusButton {
  line-height: 1rem;
}

.mobileLayout .minusButton {
  border-radius: 0px;
}
.mobileLayout .detailPage-infoBlock-buyZone .plusButton {
  width: 35px;
}
.mobileLayout .detailPage-infoBlock-buyZone .plusButton {
    margin-right: 17px;
}
.mobileLayout .detailPage-infoBlock-buyZone span:nth-of-type(2)  {
  float: right;
}
.mobileLayout .detailPage-infoBlock-buyZone  {
  width: 100%;
}

.detailPage-infoBlock-ones {
  padding-top: 10px;
  padding-bottom: 25px;
}

.mobileLayout .detailPage-infoBlock-buyZone {
    float: right;
}
.mobileLayout .detailPage-infoBlock-buyZone > span:nth-of-type(1){
    padding-left: 10px;
    padding-right: 10px;
    /*width: 30%;*/
  min-width: 75px;
}
.mobileLayout .detailPage-infoBlock-buyZone div {
  margin-left: 0px;
  justify-content: flex-end;
  text-align: center;
}
.mobileLayout .detailPage-dataBlock .shortCard-sizes {
  font-size: 12px;
  font-weight: normal;
}
.mobileLayout .detailPage-dataBlock {
    clear: both;

}

.mobileLayout .detailPage-toolbar {
  height: 56px;
  /* position: sticky !important; */
  top: 0px;
  z-index: 10000;
  background-color: white;
}

.mobileLayout .transparentBackground td {
  background-color: transparent !important;
}

.mobileLayout .detailPage-drawer-root .shortCard-priceBlock {
  box-shadow: none;
  background-color: transparent;
  font-size: 2em;
}
.mobileLayout .detailPage-drawer-root .shortCard-currentPrice {
  display: inline;
}
/******************* Detail Page *****************/
