/*!
 *  @author Serhii Kondrashov <garphild@garphild.pro>
 *  @copyright  2020 Serhii Kondrashov
 */

.container-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bottom-flex-container {
  flex: 1;
  align-items: flex-end;
  flex-direction: row;
  display: flex;
}

.block-1-3 {
  width: 33%;
}

.paper {
  background-color: white;
  margin-right: 5px;
  margin-left: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, .5);
  align-self: stretch;
  display: flex;
  flex-direction: column;
  h2 {
    width: 100%;
    text-align: center;
  }
}

.marginal {
  padding: 5px 5px 5px 5px;
}

.fullheight {
  height: 100%;
}

.linebottom {
  border-bottom: 1px solid lightgrey;
}

.container-flex-column {
  @extend .container-flex;
  flex-direction: column;
}
