.full {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 1000000;
  background-color: white;
}

.scanner-canvas-holder {
  video {
    // display: none;
    width: 100%;
  }
  canvas {
    margin-top: 10px;
    width: 100%;
    border: 1px solid #DDD;
    background: #FAFAFA;
  }
  .scanner-canvas-holder-buttonGroup {
    display: flex;
    button {
    width: 150px;
    height: 70px;
    padding: 0px;
    border: 6px solid darkgreen;
    border-radius: 10px;
    // background-color: green;
    // font-size: 50px;
    color: white;
    // position: fixed;
    // bottom: 20px;

    background-color: lightgreen;
    color: white;
    font-size: 1.5rem;
    margin-top: 5px;
    margin-left: 5px;
    }
  }
}


.barcode-image-container.disabled {
  img {
    -webkit-filter: grayscale(100%); /* Chrome, Safari, Opera */
    filter: grayscale(100%);
  }
}

.barcode-main-container {
  width: auto;
  position: fixed;
  z-index: 1001;
  top: 50%;
  right: 0px;
  img {
    width: 100px;
    right: 0px;
    position: absolute;
  }
}
